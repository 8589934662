import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const goodway_power = createIndicator({
    id: 'goodway-power',
    displayName: 'goodway-power',
    constructorScope: {
        init(context, inputCallback) {
            const interval = context.symbol.period;
            const mtx = 'MTX-1';
            if (mtx) {
                const ticker = this.PineJS.Std.ticker(context)
                    .toString()
                    .replace(/[^\w-]/g, '');
                context.new_sym(ticker + '#goodway_sfChip960', this.PineJS.Std.period(this._context));
                context.new_sym(ticker + '#bs_indicator_acc', this.PineJS.Std.period(this._context));
                context.new_sym(ticker.replace('-1', '-2') + '#bs_indicator_acc', this.PineJS.Std.period(this._context));
            }
            else
                return null;
        },
        main(context, inputCallback) {
            const interval = context.symbol.period;
            // goodway_sfChip960
            // open: "bvav_near",
            // high: "tbta_near",
            // low: "bvav_next",
            // close: "tbta_next",
            // volume: "bvav_near",
            //小台近遠月 口差和   goodway_sfChip960   MTX-1   open: "bvav_near", +  low: "bvav_next",    (單一 symbol)
            //小台近遠月 內外盤合  bs_indicator_acc    MTX-1     open: "net_buy",  +    MTX-2    open: "net_buy"   (需要用兩種 symbol 組合疊加)
            if (interval === '1') {
                const w1 = 300;
                const w2 = 960;
                const time = this._context.new_unlimited_var(this._context.symbol.time);
                context.select_sym(1);
                const bvav_near = this._context
                    .new_unlimited_var(this.PineJS.Std.open(context))
                    .adopt(this._context.new_unlimited_var(this._context.symbol.time), time, 0);
                const bvav_next = this._context
                    .new_unlimited_var(this.PineJS.Std.low(context))
                    .adopt(this._context.new_unlimited_var(this._context.symbol.time), time, 0);
                context.select_sym(2);
                const netBuy_near = this._context
                    .new_unlimited_var(this.PineJS.Std.open(context))
                    .adopt(this._context.new_unlimited_var(this._context.symbol.time), time, 0);
                context.select_sym(3);
                const netBuy_next = this._context
                    .new_unlimited_var(this.PineJS.Std.open(context))
                    .adopt(this._context.new_unlimited_var(this._context.symbol.time), time, 0);
                context.select_sym(0);
                const bvavNearSeries = this._context.new_var(bvav_near);
                const bvavNextSeries = this._context.new_var(bvav_next);
                const netBuyNearSeries = this._context.new_var(netBuy_near);
                const netBuyNextSeries = this._context.new_var(netBuy_next);
                bvavNearSeries.get(1000);
                bvavNextSeries.get(1000);
                netBuyNearSeries.get(1000);
                netBuyNextSeries.get(1000);
                const bvavSum = this._context.new_var(bvavNearSeries.get(0) + bvavNextSeries.get(0));
                const netBuySum = this._context.new_var(netBuyNearSeries.get(0) + netBuyNextSeries.get(0));
                return [bvavSum.get(0), netBuySum.get(0), 0, NaN];
            }
            else
                return [NaN, NaN, NaN, NaN, NaN];
        },
    },
    metainfo: {
        is_price_study: false,
        _metainfoVersion: 42,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        plots: [
            { id: 'll', type: 'line' },
            { id: 'mm', type: 'line' },
            { id: 'll2', type: 'line' },
            { id: 'kk', type: 'line' },
        ],
        defaults: {
            styles: {
                ll: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#ff0000',
                },
                mm: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#00ff00',
                },
                ll2: {
                    linestyle: 1,
                    linewidth: 1,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#aaaaaa',
                },
                kk: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#000000',
                },
            },
            precision: 2,
            inputs: {},
        },
        styles: {
            ll1: {
                title: 'll',
                histogramBase: 0,
                joinPoints: false,
            },
            mm: {
                title: 'mm',
                histogramBase: 0,
                joinPoints: false,
            },
            ll2: {
                title: 'll',
                histogramBase: 0,
                joinPoints: false,
            },
            kk: {
                title: 'kk',
                histogramBase: 0,
                joinPoints: false,
            },
        },
        inputs: [],
    },
});
